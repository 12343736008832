import { transition } from '../utils/misc'

export const signInReducer = (state, event) =>
  transition(state, event, {
    signedOut: {
      signInSuccess: ({ user }) => ({ state: 'signedIn', user }),
      requestNewPassword: ({ context }) => ({
        state: 'newPasswordRequired',
        context,
      }),
    },
    newPasswordRequired: {
      newPasswordSuccess: ({ user }) => ({ state: 'signedIn', user }),
    },
    signedIn: {
      signOutSuccess: () => ({ state: 'signedOut' }),
    },
  })
